import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./profile.scss";
import { ContributorType } from '../about/About';

type Props = ContributorType & {
  styleClass?: string,
}

const Profile = (props:Props) => {
  return (
    <a className={"profile " + props.styleClass}
       target="_blank"
       rel="noopener noreferrer"
       href={props.link}
       id={props.link !== "" ? 'active' : 'inactive'}
    >
      {props.img}

      <h3>{props.name}</h3>
      <div className="titles">
      {props.titles.map((title, i) =>
        <p key={i}>{title}</p>
      )}
      </div>

    </a>
  );
}


Profile.propTypes = {
  img: PropTypes.object.isRequired,
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  titles: PropTypes.array.isRequired,
};

export default Profile