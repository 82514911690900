import React, { Component } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Profile from '../profile/Profile';
import Copyright from 'components/copyright/Copyright';
import TermsOfService from 'components/termsOfService/TermsOfService';
import PrivacyPolicy from 'components/privacyPolicy/PrivacyPolicy';
import GVKeyDiclaimer from 'components/gvkeyDisclaimer/GvkeyDisclaimer';
import Footer from 'components/footer/Footer';

import setDocumentTitle from "utils/setDocumentTitle/setDocumentTitle";

import "./About.scss";


export type ContributorType = {
  img: React.ReactNode,
  link: string,
  name: string,
  titles: string[],
}

const contributors:ContributorType[] = [
  {
    img: <StaticImage src="./InSongKim.jpg" alt="In Song Kim"/>,
    link:"http://web.mit.edu/insong/www/",
    name:"In Song Kim",
    titles:["Principal Investigator","Associate Professor at MIT"],
  },
  {
    img: <StaticImage src="./AaronRudkin.jpg" alt="Aaron Rudkin"/>,
    link:"https://github.com/aaronrudkin/",
    name:"Aaron Rudkin",
    titles:["Research Fellow"],
  },
  {
    img: <StaticImage src="./RyanDelano.jpg" alt="Ryan Delano"/>,
    link:"https://www.linkedin.com/in/ryan-delano-4566371b2",
    name:"Ryan Delano",
    titles:["Data Engineer"],
  },
  {
    img: <StaticImage src="./BeccaSealy.jpg" alt="Becca Sealy"/>,
    link: "https://www.linkedin.com/in/becca-sealy/",
    name: "Becca Sealy",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./RodrigoGarza.jpg" alt="Rodrigo Garza"/>,
    link:"https://www.linkedin.com/in/rodrigo-garza-garcia",
    name:"Rodrigo Garza",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./NicholasLiu.jpg" alt="Nicholas Liu"/>,
    link:"https://www.linkedin.com/in/nicholasxliu",
    name:"Nicholas Liu",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./ColeRuehle.jpeg" alt="Cole Ruehle"/>,
    link:"https://www.linkedin.com/in/cole-ruehle-761166251",
    name:"Cole Ruehle",
    titles:["Researcher"],
  }
];

const alumni:ContributorType[] = [
  {
    img: <StaticImage src="./SoubhikBarai.jpg" alt="Soubhik Barai"/>,
    link:"",
    name:"Soubhik Barari",
    titles:[],
  },
  {
    img: <StaticImage src="./AnujBheda.jpg" alt="Anuj Bheda"/>,
    link:"",
    name:"Anuj Bheda",
    titles:[],
  },
  {
    img: <StaticImage src="./RemiCura.jpg" alt="Remi Cura"/>,
    link:"http://remi.cura.info/",
    name:"Remi Cura",
    titles:["PhD, Project Management","Database, Machine Learning"],
  },
  {
    img: <StaticImage src="./JohnGallagher.jpg" alt="John Gallagher"/>,
    link:"http://www.johnjamesgallagher.com/",
    name:"John Gallagher",
    titles:["Project Management, Backend"],
  },
  {
    img: <StaticImage src="./YoungHwanJoo.jpg" alt="Young Hwan"/>,
    link:"https://rulyox.com",
    name:"YoungHwan Joo",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./DmitriyKunisky.jpg" alt="Dmitriy Kunisky"/>,
    link:"",
    name:"Dmitriy (Tim) Kunisky",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./ChrisLay.jpg" alt="Chris Lay"/>,
    link:"https://www.linkedin.com/in/chrisklay/",
    name:"Christopher Lay",
    titles:["Data Visualizations"],
  },
  {
    img: <StaticImage src="./HarryLi.jpg" alt="Harry Li"/>,
    link:"https://harryxli.com/",
    name:"Harry Li",
    titles:["Frontend Technical Lead","Data Visualizations"],
  },
  {
    img: <StaticImage src="./ArielMa.jpg" alt="Ariel Ma"/>,
    link:"https://www.linkedin.com/in/chiaman-ma/",
    name:"Ariel Ma",
    titles: ["UI/UX Design"],
  },
  {
    img: <StaticImage src="./AdamRauh.jpg" alt="Adam Rauh"/>,
    link:"https://adamrauh.github.io/",
    name:"Adam Rauh",
    titles:["Researcher"],
  },
  {
    img: <StaticImage src="./MaddieSchwartz.jpg" alt="Maddie Schwartz"/>,
    link:"https://maddoschwartz.github.io/",
    name:"Madeleine Schwartz",
    titles:["UI/UX Design"],
  },
  {
    img: <StaticImage src="./NickSempere.jpg" alt="Nick Sempere"/>,
    link:"",
    name:"Nick Sempere",
    titles:[],
  },
  {
    img: <StaticImage src="./JeffreyShen.jpg" alt="Jeffrey Shen"/>,
    link:"https://jeffreyshen.com",
    name:"Jeffrey Shen",
    titles:["Data Visualizations"],
  },
  {
    img: <StaticImage src="./SydneyStrzempko.jpg" alt="Sydney Strzempko"/>,
    link:"https://stzuko.github.io/",
    name:"Sydney Strzempko",
    titles:["API, Visualizations,","Frontend & Backend"],
  },
  {
    img: <StaticImage src="./LexiWalker.jpg" alt="Lexi Walker"/>,
    link:"https://lexi-walker.github.io/",
    name:"Lexi Walker",
    titles:[],
  },
  {
    img: <StaticImage src="./SuyeolYun.jpg" alt=" Suyeol Yun"/>,
    link:"",
    name:"Suyeol Yun",
    titles:["Databases"],
  }
];

export default class About extends Component {
  constructor(props) {
    super(props)

    setDocumentTitle("About")
  }

  render() {
    return (
      <div id="about">
        <div id="team">
          <Container>
            <h1>Meet the Team</h1>
            <Row>
              {contributors.map ((contributor, i) =>
                <Col xs={12} lg={4} key={i}>
                  <Profile {...contributor}/>
                </Col>
              )}

              <Col id="get-involved">
                <div>
                  <h1>Get Involved</h1>
                </div>
              <p>
	      Would you like to contribute your skills or research to the project? LobbyView is constantly expanding with new data, functionality, and research. Please email us with the subject line “collaborate” to get in touch with our researchers.
              </p>
              <a id="email" href="mailto:lobbydata@gmail.com">Email LobbyView {">"}</a>
              </Col>
            </Row>
          </Container>
        </div>


        <div id="alumni">
          <Container>
            <h1>Alumni</h1>
            <div className="underline"></div>
            <Row>
              {alumni.map((contributor, i) =>
                <Col sm={6} lg={3} key={i}>
                  <Profile {...contributor} styleClass="miniprofile" titles={[]} />
                </Col>
              )}
              </Row>
          </Container>
        </div>

        <Menu
          menuitems={MenuItems}
          menucontent={MenuContent}
        />

        <Footer />

      </div>
    )
  }
}

const MenuItems = [
  'Privacy Policy',
  'Copyright',
  'Terms of Service',
  'GVKey Disclaimer'
]

const MenuContent = [
  <PrivacyPolicy/>,
  <Copyright/>,
  <TermsOfService/>,
  <GVKeyDiclaimer/>
]

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentIndex: 0
    }
  };

  setContent = (i) => {
    this.setState({contentIndex: i});
  };

  render() { return (
    <div id="info">
      <Container>
        <Row>
          <Col lg={3}>
            <div id="menu">
              {this.props.menuitems.map ((item, i) =>
                <div className="item" key={i} onClick={()=>this.setContent(i)}>{item}</div>
              )}
            </div>
          </Col>
          <Col>
            <div className="menuContent">
              {this.props.menucontent[this.state.contentIndex]}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )}
}
